<template>
  <div class="row">
    <div class="col-12 wrapper-box-fiche"  v-if="data.prix_new_zealand">   
        <div class="box-white-fiche">
            <table class="comparateur2 border-top-gray">
                <thead>
                    <tr>
                        <th class="gray" width="10%"><strong> {{$t('Price (Manufacturers Price)$')}} </strong></th>
                        <th class="gray" width="20%"><strong> {{$t('Classification Antiplatelets')}} </strong></th>
                        <th class="gray" width="10%"><strong> {{$t('Classification Pharmacode')}} </strong></th>
                        <th class="gray" width="10%"><strong> {{$t('NZMT CTPP ID')}} </strong></th>
                        <th class="gray" width="10%"><strong> {{$t('Subsidy')}} </strong></th>
                        <th class="gray" width="10%"><strong> {{$t('Alternate')}} </strong></th>
                        <th class="gray" width="10%"><strong> {{$t('Fully Subsidised')}} </strong></th>
                        <th class="gray" width="10%"><strong> {{$t('S26/S29')}} </strong></th>
                        <th class="gray" width="10%"><strong> {{$t('Original Pack')}} </strong></th>
                    </tr>
                </thead>
                <tr>
                    <td>{{ data.prix_new_zealand['price'] ? format_french_number(data.prix_new_zealand['price']) : '-'}}</td>
                    <td>{{ data.prix_new_zealand['classification'] ? data.prix_new_zealand['classification'] : '-'}}</td>
                    <td>{{ data.prix_new_zealand['pharma_code'] ? data.prix_new_zealand['pharma_code'] : '-'}}</td>
                    <td>{{ data.prix_new_zealand['nzmt_ctpp_id'] ? data.prix_new_zealand['nzmt_ctpp_id'] : '-'}}</td>
                    <td>{{ data.prix_new_zealand['subsidy'] ? format_french_number(data.prix_new_zealand['subsidy']) : '-'}}</td>
                    <td>{{ data.prix_new_zealand['alternate'] ? format_french_number(data.prix_new_zealand['alternate']) : '-'}}</td>
                    <td>{{ data.prix_new_zealand['fully_subsidised'] ? data.prix_new_zealand['fully_subsidised'] : '-'}}</td>
                    <td>{{ data.prix_new_zealand['S26_S29'] ? data.prix_new_zealand['S26_S29'] : '-'}}</td>
                    <td>{{ data.prix_new_zealand['original_pack'] ? data.prix_new_zealand['original_pack'] : '-'}}</td>
                </tr>
            </table>
        </div>
    </div> 
</div>
</template>

<script>
import { format_french_number } from '../../../utils';  

export default {
    name : 'PrixNewZealand',
    computed : {
        data() {
            return this.$store.getters['detail/data']
        }
    },
    methods : {
        format_french_number
    }
}
</script>

<style>

</style>